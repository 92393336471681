import {Box, Center, HStack, Image, Link, Spacer, VStack} from '@chakra-ui/react';
import * as React from 'react';

export const Header = () => (
    <VStack w='100%'>
        <HStack w='100%' h='100%'>
            <Box w={['80px', null, '100px']} h='100%'>
                <Link href='/'>
                    <Center h='100%'>
                        <Image src={'assets/sw_logo_transparent.png'} alt='StimmWohl Logo' w='60%'/>
                    </Center>
                </Link>
            </Box>
            <Spacer/>
            {/* Burger or something else here */}
        </HStack>
    </VStack>
);
