import {Divider, Flex, HStack, Icon, Link, Text} from '@chakra-ui/react';
import {Link as ReactRouterLink} from 'react-router-dom';
import * as React from 'react';
import {format, getYear} from 'date-fns';
import buildInfo from '../buildInfo';
import {FaInstagram, FaStamp} from 'react-icons/fa';

const buildDate = new Date(buildInfo.buildDate);

export const Footer = () => (
    <Flex flexDirection='column' borderTop='8px' borderColor='#573195' w='100%' bg='#112525' align='stretch'>
        <Flex h='70%' w='100%' alignItems='center' justifyContent='center' p={['25px', null, '20px']}>
            <Link isExternal href='https://www.instagram.com/stimmwohl' mr={['18px',null,'60px']}>
                <HStack>
                    <Text variant='white' fontSize={['0.8rem', '1rem']}>
                        Instagram
                    </Text>
                    <Icon as={FaInstagram}></Icon>
                </HStack>
            </Link>
            <Link as={ReactRouterLink} to='/impressum' mr={['18px',null,'60px']}>
                <HStack>
                    <Text variant='white' fontSize={['0.8rem', '1rem']}>
                        Impressum
                    </Text>
                    <Icon as={FaStamp}></Icon>
                </HStack>
            </Link>
        </Flex>
        <Divider color='#573195' orientation='horizontal'/>
        <Flex gap={[2, null, 50]} flexDirection={['column', null, 'row']} w='100%' alignItems='center'
              justifyContent='center' p={['6px', null, '9px']}>
            <Text variant='white' fontSize={['0.6rem', '0.8rem']}>
                ©&nbsp;{getYear(buildDate)}&nbsp;Copyright&nbsp;
                <Link isExternal href='https://m1well.com'>
                    https://m1well.com
                </Link>
            </Text>
            <Text variant='white' fontSize={['0.6rem', '0.8rem']}>
                Letztes&nbsp;Update&nbsp;{format(buildDate, 'dd.MM.yyyy')}
            </Text>
        </Flex>

    </Flex>
);
