import {extendTheme} from '@chakra-ui/react';

const Theme = extendTheme({
    fonts: {
        body: '\'Josefin Sans Variable\', \'Tahoma\', sans-serif',
    },
    components: {
        Text: {
            baseStyle: () => ({
                color: '#0a1717',
            }),
            variants: {
                'white': {
                    color: '#eef8f8',
                }
            }
        },
    },

    styles: {
        global: () => ({
            // Optionally set global CSS styles
            body: {
                color: 'white',
            },
        }),
    },
});

export default Theme;
