import {Image, Text, VStack} from '@chakra-ui/react';
import * as React from 'react';

export const Content = () => (
    <VStack>
        <Image src={'assets/sw_banner_transparent_m_logo.png'} alt='StimmWohl Banner' w={['70%',null,'55%']}
               pt={['200px', '250px']}/>
        <Text pt='20px' px='5px' fontSize={['1.1rem', '1.8rem']}>Die Seite ist aktuell im Aufbau....</Text>
    </VStack>
);
