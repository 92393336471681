import * as React from 'react';
import {Box, ChakraProvider, CSSReset, Flex} from '@chakra-ui/react';
import {Footer} from './layout/Footer';
import {Content} from './layout/Content';
import {Header} from './layout/Header';
import Theme from './theme';
import '@fontsource-variable/josefin-sans';
import {Route, Routes} from 'react-router';
import {Impressum} from './component/Impressum';

export const App = () => {
    return (
        <ChakraProvider theme={Theme} cssVarsRoot="body">
            <CSSReset/>
            <Box w='100%' minWidth='270px' position='relative'>
                <Flex w='100%' h={['60px', null, '70px']} position='fixed'
                      backgroundColor="rgba(87,187,187,0.8)" backdropFilter="saturate(180%) blur(5px)">
                    <Header/>
                </Flex>
                <Flex pb={['130px', '135px']} w='100%' minHeight='100vh'>
                    <Flex w='100%' bgGradient='linear(to-br,#c0c7c7,#f1f9f9,#ddf1f1)' mt={['60px', null, '70px']}>
                        <Routes>
                            <Route path="/" element={<Content/>}/>
                            <Route path="/impressum" element={<Impressum/>}/>
                        </Routes>
                    </Flex>
                </Flex>
                <Flex w='100%' height={['130px', '135px']} bottom='0' position='absolute'>
                    <Footer/>
                </Flex>
            </Box>
        </ChakraProvider>
    );
};
